var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app"},[_c('div',{staticClass:"container-box"},[_c('nav-bar'),(!_vm.featureFlagStakeOnly && _vm.currentCharacterId !== null)?_c('character-bar'):_vm._e(),_c('div',{staticClass:"content dark-bg-text"},[(_vm.canShowApp)?_c('router-view'):_vm._e()],1),(!_vm.hideWalletWarning && (_vm.showMetamaskWarning || _vm.showNetworkError))?_c('div',{staticClass:"fullscreen-warning"},[_c('div',{staticClass:"starter-panel"},[_c('span',{staticClass:"starter-panel-heading"},[_vm._v("Metamask Not Detected Or Incorrect Network")]),_c('div',{staticClass:"center row"},[(_vm.showMetamaskWarning)?_c('big-button',{staticClass:"button",attrs:{"mainText":"Add MetaMask"},on:{"click":_vm.startOnboarding}}):_vm._e(),(_vm.showNetworkError)?_c('big-button',{staticClass:"button",attrs:{"mainText":"Switch to BSC Network"},on:{"click":_vm.configureMetaMask}}):_vm._e(),_c('small-button',{staticClass:"button btn-primary",attrs:{"text":'Hide Warning'},on:{"click":_vm.toggleHideWalletWarning}})],1)])]):_vm._e(),(
        !_vm.hideWalletWarning &&
        !_vm.showMetamaskWarning &&
        (_vm.errorMessage ||
          (_vm.ownCharacters.length === 0 &&
            _vm.skillBalance === '0' &&
            !_vm.hasStakedBalance))
      )?_c('div',{staticClass:"fullscreen-warning"},[_c('div',{staticClass:"starter-panel"},[_c('span',{staticClass:"starter-panel-heading"},[_vm._v(_vm._s(_vm.errorMessage || "Get Started With CryptoWars"))]),_c('div',{staticClass:"instructions-list"},[_c('p',[_vm._v(" To recruit your first character you need "+_vm._s(_vm.recruitCost)+" xBlade and .001 BNB for gas. You will also need .0015 BNB to do your first few battles, but don't worry, you earn the battle fees back in xBlade rewards immediately! ")]),_vm._m(0)]),_c('button',{staticClass:"hide-modal",on:{"click":_vm.toggleHideWalletWarning}},[_c('img',{attrs:{"src":require("./assets/images/btn-close.svg")}})]),_c('div',{staticClass:"button-div"},[_c('big-button',{staticClass:"button mm-button",attrs:{"mainText":"Configure MetaMask"},on:{"click":_vm.configureMetaMask}}),_c('big-button',{staticClass:"button mm-button",class:[_vm.isConnecting ? 'disabled' : ''],attrs:{"mainText":"Connect to MetaMask"},on:{"click":_vm.connectMetamask}})],1)])]):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v(" Join us on "),_c('a',{attrs:{"href":"https://discord.gg/MQBWnE8t2G","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("Discord")]),_vm._v(" / "),_c('a',{attrs:{"href":"https://t.me/elasticbitcoinxbt","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("Telegram")]),_vm._v(" / "),_c('a',{attrs:{"href":"https://twitter.com/xbladecryptowar","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("Twitter")])])}]

export { render, staticRenderFns }