















































import Vue from "vue";

import ViewLinks from "./ViewLinks.vue";
import Options from "./Options.vue";
import SkillBalanceDisplay from "./smart/SkillBalanceDisplay.vue";
import ClaimRewards from "./smart/ClaimRewards.vue";
import ClaimRewardsBar from "./smart/ClaimRewardsBar.vue";

import Events from "../events";
import { mapGetters, mapMutations } from "vuex";

export default Vue.extend({
  components: {
    ViewLinks,
    SkillBalanceDisplay,
    ClaimRewards,
    ClaimRewardsBar,
    Options,
  },

  data() {
    return {
      canShowRewardsBar: true,
    };
  },

  computed: {
    ...mapGetters(["getIsCharacterViewExpanded", "ownCharacters"]),
  },

  methods: {
    ...mapMutations(["setIsCharacterViewExpanded"]),
    checkStorage(): void {
      this.canShowRewardsBar = localStorage.getItem("hideRewards") === "false";
    },
    toggleCharacterView(): void {
      this.setIsCharacterViewExpanded(!this.getIsCharacterViewExpanded);
      localStorage.setItem(
        "isCharacterViewExpanded",
        this.getIsCharacterViewExpanded ? "true" : "false"
      );
    },
  },

  mounted() {
    this.checkStorage();
    Events.$on("setting:hideRewards", () => this.checkStorage());
  },
});
